import React, { useState, useEffect } from 'react';

const Gallery = ({ images }) => {
    const imagesPerView = 4; // Number of images to display at a time
    const [currentSet, setCurrentSet] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSet((prevSet) => (prevSet + 1) % Math.ceil(images.length / imagesPerView));
        }, 5000); // Change set every 5 seconds

        return () => clearInterval(interval);
    }, [images.length, imagesPerView]);

    const startIndex = currentSet * imagesPerView;
    const displayedImages = images.slice(startIndex, startIndex + imagesPerView);

    return (
        <div className="flex justify-center items-center bg-gray-100 py-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4 max-w-6xl mx-auto">
                {displayedImages.map((image, index) => (
                    <div
                        key={index}
                        className="overflow-hidden rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out"
                    >
                        <img
                            src={image}
                            alt={`Gallery item ${index}`}
                            className="object-cover w-full h-64 transition-transform duration-500 ease-in-out transform hover:scale-110"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
