import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import hero_image from "../assets/hero_image_one.jpeg"
import logo from "../assets/logo2.png"
import soccer from "../assets/soccer.jpeg"
import Gallery from '../components/gallery';
const HomePage = () => {
    function importAll(r) {
        return r.keys().map(r);
    }
    const [currSection, setCurrSection] = useState(0)
    const images = importAll(require.context('../assets/gallery', false, /\.(png|jpe?g|svg)$/));

    // State to hold the current image index
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Change image every few seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(currentImageIndex => (currentImageIndex + 1) % images.length);
        }, 3000); // Rotate every 3 seconds

        return () => clearInterval(interval);
    }, [images.length]);
    const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null)]; // Refs for each section

    const pillars = [
        {
            title: 'Teamwork',
            body: `At San Diego Chill Out, teenage coaches foster teamwork and meaningful connections, creating more than a sports program, but a supportive community for children with developmental disabilities and their families. Together, we're dedicated to nurturing joy, understanding, and thriving one child at a time.`
        },
        {
            title: 'Mentorship',
            body: 'Our mentorship program is a cornerstone of the San Diego Chill Out experience. By pairing participants with near-age mentors, we provide relatable role models who inspire confidence and growth. This close-age connection allows for deeper understanding, genuine friendships, and a supportive environment where our youth can thrive.'
        },
        {
            title: 'Community',
            body: 'The San Diego Chill Out focuses on creating a supportive community for children with developmental disabilities and their families. It provides a nurturing environment for kids, along with respite and networking opportunities for parents, fostering strong connections and lasting relationships.'
        }
    ]

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
                    const sectionIndex = sectionRefs.findIndex((ref) => ref.current === entry.target);
                    setCurrSection(sectionIndex);
                }
            });
        }, {
            threshold: [0.8], // Trigger when 50% of the target is visible
            rootMargin: '0px'
        });

        sectionRefs.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            sectionRefs.forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, [sectionRefs]);



    const pages = [
        {
            title: 'Home'
        },
        {
            title: 'About'
        },
        {
            title: 'Mission'
        },
        {
            title: 'Contact'
        },
        {
            title: 'Donate',
            additionalStyling: ' bg-yellow-500 py-2 px-2 rounded-md text-white'
        },
    ]
    const inpSty = "w-full border border-gray-300 rounded-md px-2 py-3 my-2"

    const scrollToSection = (sectionIndex) => {
        if (sectionRefs[sectionIndex] && sectionRefs[sectionIndex].current) {
            sectionRefs[sectionIndex].current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="w-full flex flex-col h-screen overflow-y-scroll">
            <div className="fixed w-full flex top-1 transition-all z-[100]">
                <div className="z-[100] w-full md:w-2/3 mt-4 py-1 px-1 shadow-md transition-all rounded-md mx-auto border border-gray-200 justify-between flex bg-white backdrop-blur backdrop-filter bg-blur-xl bg-opacity-70">
                    {
                        pages.map((key, idx) => {
                            return (
                                <div onClick={() => scrollToSection(idx)} className={`${currSection === idx ? ' cursor-pointer bg-blue-500 py-2 px-2 backdrop-blur backdrop-filter bg-blur-xl bg-opacity-80  rounded-md bg-opactiy-80 text-white' : ''} my-auto transition-all px-2 cursor-pointer ${key?.additionalStyling}`}>{key?.title}</div>
                            )
                        })
                    }
                </div>

            </div>
            <div className="flex flex-col">


                <div className="h-[90vh] bg-gray-50 w-full flex flex-col relative pb-12" ref={sectionRefs[0]} >

                    {/* <div className="my-auto text-center text-8xl z-[60] border-4 border-gray-500 bg-white text-blue-500 backdrop-blur bg-blur-xl bg-opacity-70 shadow-xl w-2/3 mx-auto rounded-md"> */}
                    <img className="my-auto z-[60] md:w-[20vw] w-[100vh] mx-auto shadow-inner shadow-2xl shadow-black bg-white rounded-md p-12 backdrop-blur bg-blur-xl bg-opacity-20 border-2 border-blue-400" src={logo}></img>
                    {/* </div> */}
                    <div className="absolute bg-slate-50 w-full h-full object-cover">

                        {/* <div className="w-full h-full bg-white absolute"></div> */}
                        <img className="object-cover md:w-full h-full opacity-60" src={hero_image}></img>
                    </div>

                </div>

                <div className=" w-2/3 mx-auto flex flex-col px-4 my-24 " ref={sectionRefs[1]}>
                    <div className=" mt-24 mb-6">
                        <span className="border border-gray-200 shadow-sm px-2 py-2 rounded-md "  >About</span>
                    </div>
                    <div className="">

                        At the San Diego Chill Out, we open doors to joy and camaraderie for kids, teens and young adults with developmental challenges. Our mission is simple yet profound: to ensure every child, regardless of their abilities, has a chance to experience the thrill of sports, the excitement of activities, and the joy of community events. We imagine the sparkle in the eyes of a child as they hit a baseball, score a soccer goal, or soar through the air on a zipline. This is what we make possible at the Chill Out, with a variety of sports and activities tailored for kids aged 7 and up.
                        <br></br>
                        <br></br>
                        <span className="border border-gray-200 rounded-md px-1 py-1 shadow-sm font-light text-sm mr-2">What Makes Us Unique</span>
                        Our coaches and mentors are teenagers themselves, bringing an energy and understanding that only peers can offer. This connection fosters deep, meaningful relationships, helping our young participants feel understood and valued.
                        More Than Just Sports
                        We're more than just a sports program. We're a community. For parents of children with developmental disabilities, the Chill Out offers a much-needed respite, a place where they know their kids are not just safe and supported, but truly thriving.
                        Join Us
                        Join us in making a difference, one smile, one game, one child at a time.
                    </div>
                </div>
                <div
                    className="relative flex items-center justify-center"
                    style={{
                        backgroundImage: `url(${soccer})`,
                        backgroundAttachment: 'fixed',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                    ref={sectionRefs[3]} // if you want to include it in your Intersection Observer
                >
                    <div className=" w-full flex flex-col px-4 py-12 h-full">
                        <div className="md:w-2/3 w-full mx-auto flex md:flex-row flex-col justify-between md:space-x-4 transition-all">


                            {
                                pillars.map((key, idx) => {
                                    return (
                                        <div className="flex 
                                        md:mx-none mx-auto
                                        backdrop-blur bg-blur-xl bg-opacity-80
                                    flex-col border border-gray-500 bg-gray-50 rounded-md shadow-inner
                                        shadow-black
                                        transition-all
                                     md:w-2/3 w-4/5 md:my-none my-4 h-96 cursor-pointer 
                                      hover:shadow-sm 
                                      hover:shadow-black
                                     px-4
                                     duration-500 
                                     transition-all">
                                            <div className="text-center text-lg font-bold pb-4 pt-4 md:text-lg text-sm">{key?.title}</div>
                                            <div className="text-center text-lg font-light md:text-md text-sm">{key?.body}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className=" w-2/3 mx-auto flex flex-col px-4 my-40 " ref={sectionRefs[2]}>
                    <div className=" mt-24 mb-6">
                        <span className="border border-gray-200 shadow-sm px-2 py-2 rounded-md "  >Mission</span>
                    </div>
                    <div className="">
                        The mission of the San Diego Chill Out is to enrich the lives of youth with developmental disabilities through sports, forging meaningful connections with near-age mentors. We create a supportive space for both children and parents, fostering a community of inclusion, resilience, and joy.             </div>

                </div>
                <div className="w-full flex flex-col items-center my-12">
                    <div className="text-2xl font-bold text-blue-500 mb-6">
                        Upcoming Events
                    </div>

                    <div className="w-3/4 md:w-1/2 bg-white shadow-lg rounded-lg p-6 border border-gray-200 mb-4">
                        <div className="text-lg font-semibold text-gray-800 mb-2">
                            January 21st, 1-3:30 PM
                        </div>
                        <div className="text-md text-gray-700 mb-2">
                            <strong>Flag Football with the San Diego Chill Out!</strong>
                        </div>
                        <div className="text-sm text-gray-600">
                            Join Coach Jonte Berry, owner of the Del Mar NFL Flag Football and former professional athlete, along with the Chill Out Coaches for a fun afternoon of Flag Football.
                        </div>
                        <div className="text-sm text-gray-600 mt-2">
                            <strong>Location:</strong> Allen Field, 3908 Torrey Pines Rd, La Jolla, CA 92037
                        </div>
                    </div>

                    <div className="w-3/4 md:w-1/2 bg-white shadow-lg rounded-lg p-6 border border-gray-200">
                        <div className="text-lg font-semibold text-gray-800 mb-2">
                            February 25th, 1-4:00 PM
                        </div>
                        <div className="text-md text-gray-700 mb-2">
                            <strong>Outback Adventure Challenge Ropes Course and Zip Line!</strong>
                        </div>
                        <div className="text-sm text-gray-600">
                            Experience an exciting day at the Outback Adventure Challenge, featuring a ropes course and zip line.
                        </div>
                        <div className="text-sm text-gray-600 mt-2">
                            <strong>Location:</strong> UCSD North Point Lane, La Jolla, CA 92093
                        </div>
                    </div>
                </div>
                <Gallery images={images} />

                <div className=" w-2/3 mx-auto flex flex-col px-4 my-24 " >
                    <div className=" my-2">
                        <span className="border border-gray-200 shadow-sm px-2 py-2 rounded-md "  >Contact</span>
                    </div>
                    <div className="flex flex-col" ref={sectionRefs[3]}>

                        <div className="flex space-x-2">
                            <input className={inpSty} placeholder='First Name'></input> <input className={inpSty} placeholder='Last Name'></input>
                        </div>
                        <input className={inpSty} placeholder='Email'></input>
                        <textarea className={inpSty} placeholder='A brief message....'></textarea>
                        <button className="w-full rounded-md bg-blue-500 py-2 hover:bg-blue-600 text-white">Submit</button>
                    </div>
                </div>
                <div className="h-[5vh] bg-blue-900 flex w-full">
                    <div className=" mx-auto my-auto text-white italic font-light ">Copyright San Diego Chill 2023</div>
                </div>
            </div>

        </div>
    )
}

export default HomePage;
