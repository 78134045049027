import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<HomePage />} />

      </Routes>
    </BrowserRouter>
  );
  //
}
// sshd
//
export default App;
